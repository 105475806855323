import { HubspotFormSubmissionProps } from "@/types/hubspot";
import { convertDataToFields, DEFAULT_HUBSPOT_PORTAL_ID } from "@/utils/hubspot";
import { debounce } from "lodash/fp";
import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef } from "react";
const ENDPOINT = "https://api.hsforms.com/submissions/v3/integration/submit";
type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Make a submission to a HubSpot form.
 * @param formId - ID of form we're sending data to.
 * @param data - Structured data for sending.
 */
export const sendDataToForm = debounce(200, ({
  formId,
  data,
  portalId = DEFAULT_HUBSPOT_PORTAL_ID
}: HubspotFormSubmissionProps) => {
  return fetch(`${ENDPOINT}/${portalId}/${formId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      submittedAt: new Date().getTime(),
      fields: convertDataToFields(data),
      context: {
        hutk: null
      }
    })
  });
});
export interface FormProps extends DivProps {
  id: string;
  formId: string;
  onBeforeFormSubmit?: (form: any, values: any) => void;
  portalId?: string;
}

// TODO: Make sure that rawHTML forms are themed correctly.
export const Form = ({
  id,
  formId,
  onBeforeFormSubmit,
  portalId = DEFAULT_HUBSPOT_PORTAL_ID,
  ...props
}: FormProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hbspt && ref.current) {
      hbspt.forms.create({
        portalId,
        formId,
        target: "#" + ref.current!.id,
        onBeforeFormSubmit
      });
    }
  }, [hbspt, ref.current]);
  return <div id={id} ref={ref} {...props} data-sentry-component="Form" data-sentry-source-file="index.tsx" />;
};