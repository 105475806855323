import { Widgets } from "../../types/widgets";

const widgets: Widgets = {
  addressAutocomplete: {
    prompt: "Enter asset address",
  },
  firstTimeLogin: {
    title: "Customer Notification",
    warning: "Please be advised that the Climate Risk Hub is undergoing continuous refinement and improvement.",
    body: `While you may notice occasional updates or adjustments to the
    system's features, the underlying climate risk models and underlying data
    remain stable, robust, and thoroughly peer-reviewed, ensuring accurate,
    consistent and reliable insights.\n
    Your feedback is invaluable, and we encourage you to share any observations
    or suggestions to help us further improve the platform's performance and
    functionality.`,
    button: "I Understand",
  },
  versionUpdate: {
    title: "Update Notification",
    body: `Based on the platform's current version number, it seems certain
    updates have been made since your last login. For more information on the
    most recent changes, please visit the platform's changelog
    (Help > Changelog).

    If you have any questions or feedback regarding these updates,
    please contact customer support.`,
    button: "I Understand",
  },
  map: {
    hints: {
      click: "Hint: Click map to move the pin if the search result is not accurate.",
    },
  },
  notifications: {
    title: "Notifications",
    hints: {
      empty: "No new notifications.",
    },
  },
  textInput: {
    addresses: {
      countOverLimit: "Too many addresses entered ({{count}}/{{max}}).",
      countWithinLimit: "{{count}}/{{max}} addresses entered.",
      placeholder: "Paste a list of assets here (maximum {{count}})",
    },
  },
  upload: {
    csv: {
      hints: {
        first10Lines: "Note: Only the first 10 lines of this file will be used.",
      },
      prompt: "Upload data via .csv file",
    },
    om: {
      prompt: "Select .csv file containing OMs",
    },
  },
};

export default widgets;
