import { Pages } from "../../types/pages";

const pages: Pages = {
  assetLibrary: {
    title: "Data",
    addData: {
      title: "Add Data",
    },
  },
  login: {
    title: "Welcome back",
    subtitle: "Log in to your account",
    form: {
      email: "Email",
      password: "Password",
      button: "Log in",
    },
  },
  signUp: {
    title: "Get started",
    subtitle: "Create your account",
    form: {
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      passwordPrompt:
        "We recommend using a password manager to generate a random password that is unique, long, and complex.",
      button: "Sign up",
    },
  },
  forgotPassword: {
    title: "Reset password",
    subtitle:
      "Enter your email address and we'll send you a one-time passcode (OTP) to verify your password reset request.",
    form: {
      email: "Email",
      otp: "One-time passcode (OTP)",
      otpHelp: "Your OTP has been sent. Please check your email.",
      signInWithOtpError: "Something went wrong while sending your OTP. Please try again.",
      verifyOtpError: "Something went wrong while verifying your OTP. Please try again.",
    },
  },
  changePassword: {
    title: "Set a password",
    subtitle:
      "As part of the security features enabled for your organisation, you are required to update your password every 180 days. Please enter a new password to continue.",
    form: {
      oldPassword: "Current password",
      newPassword: "New password",
      confirmNewPassword: "Confirm new password",
      changePasswordError: "Something went wrong while changing your password. Please try again.",
    },
  },
  contactSupport: {
    title: "Having trouble logging in, or have an enquiry?",
    subtitle: "",
    form: {
      username: "Email or username",
      organisation: "Organisation",
      message: "How can we help?",
      fileUpload: "File upload",
      button: "Submit",
      errorUploadMessage: "Something went wrong while attaching your files. Please try again.",
      errorMessage: "Something went wrong while submitting your enquiry.",
    },
    privacyPolicy: "Privacy Policy",
  },
  dashboards: {
    title: "Dashboards",
    drawer: {
      title: "Edit Dashboard",
      moduleDeleteConfirmation: "Are you sure you want to delete this module?",
    },
    errors: {
      notFound: "Cannot find dashboard",
    },
  },
  knowledgeBase: {
    title: "Knowledge Base",

    glossary: {
      title: "Glossary",
    },
    method: {
      title: "Methods & FAQ",
    },
  },
};

export default pages;
