import { Data, Field } from "@/types/hubspot";

export const DEFAULT_HUBSPOT_PORTAL_ID = "7735589";
export const DEFAULT_AUTH_BEARER_TOKEN = "Bearer pat-na1-63353572-e31a-4c70-bc9e-99b80036f934";

export const convertDataToFields = (data: Data): Field[] => {
  return Object.keys(data).map((name) => {
    return { name, value: data[name] };
  });
};
