import { RemoteJobTableHeader, RemoteJobTableHeaderProps } from "@/features/remote_jobs";
import { useGetToolUsageQuery } from "@/store/services/supabase";
import { PortfolioExportNewRunModal } from "@/tools/aggregate/portfolio-export/components/PortfolioExportNewRunModal";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { useRouter } from "next/router";
import { FC } from "react";

/**
 * Header for the Portfolio Export tool's table.
 */
export const PortfolioExportTableHeader = (props: RemoteJobTableHeaderProps<PortfolioExportJobSchema>) => {
  const router = useRouter();
  const {
    data
  } = useGetToolUsageQuery({
    ignoreDateFrom: true
  });
  const openModalByDefault = Object.keys(router.query).includes("handoff");
  return <RemoteJobTableHeader {...props} newRunModalComponent={PortfolioExportNewRunModal as FC} requestNotification={true} openModalByDefault={openModalByDefault} disableNewRun={!data?.newRun.enabled || false} data-sentry-element="RemoteJobTableHeader" data-sentry-component="PortfolioExportTableHeader" data-sentry-source-file="PortfolioExportTableHeader.tsx" />;
};